import { AvvNodeGrammar, HtmlParser } from '@avvoka/editor'
import { computed, ref, watch } from 'vue'
import { CompareUtils } from '../../javascripts/editors/compare'
import Utils from '../utils'
import { getCurrentQuestionnaireData } from '../QuestionnaireHelpers'
import ExportApi from '@api/Documents/ExportApi'
import DocumentsApi from '@api/DocumentsApi'
import DownloadDialog from "~/features/download_dialog/DownloadDialog.vue";

const axios = Utils.axios

export const onTopbarMounted = async () => {
  const { valid_conditions } = await getCurrentQuestionnaireData()
  AvvStore.commit('SET_RESOLVED_CONDITIONS', valid_conditions)
}

export const goTo = (url, event) => {
  if (event && event.target.classList.contains('disabled')) return
  window.location.href = url
}

const isResolvedQuestion = (
  question: Backend.Questionnaire.IQuestion,
  loopCount = null
) => {
  // todo need better handling here
  if (!question.cond) return true
  else {
    const visCond = AvvStore.state.resolvedConditions[question.cond]
    return Array.isArray(visCond) ? visCond.includes(loopCount) : visCond
  }
}

const isRequiredUnanswered = (question: Backend.Questionnaire.IQuestion) =>
  question.opts?.required &&
  !AvvStore.state.answers[question.att] &&
  !question.opts?.default &&
  isResolvedQuestion(question)
const bHasNonAnsweredQs = computed(() =>
  AvvStore.getters.nonDeletedQuestions
    .filter((q) => q.party === AvvStore.state.active_participant?.party_type)
    .some(isRequiredUnanswered)
)

export const init_questionnaire_button = (props) => {
  const path = 'document.topbar.tooltips.questionnaire'
  const hasRights = AvvStore.state.active_participant?.edit !== 'No'
  const currentParty = AvvStore.state.active_participant?.party_type
  const isController = AvvStore.state.isCurrentParticipantController
  const isLockedToCurrentParty = computed(
    () => AvvStore.state.locking_party === currentParty
  )
  const onePartyOnly = AvvStore.getters.parties.length === 1

  const bShowQuestionnaireButton = computed(() => {
    const hasQuestionsForCurrentParty =
      !!AvvStore.getters.nonDeletedQuestions.filter(
        (q) => q.party === AvvStore.state.active_participant?.party_type
      ).length
    const invalidStates = [
      'pending_external_signature',
      'partially_signed',
      'signed',
      'completed',
      'deleted'
    ]
    const isInValidState = !invalidStates.includes(AvvStore.state.doc_state)
    return hasQuestionsForCurrentParty && isInValidState
  })

  const bQuestionnaireButtonDisabled = computed(() =>
    AvvStore.state.is_doc_negotiable
      ? !hasRights || (!isLockedToCurrentParty.value && !onePartyOnly)
      : !hasRights
  )

  const questionnaireButtonTooltip = computed(() => {
    if (!hasRights) return localizeText(`${path}.no_perm`)
    if (AvvStore.state.doc_state === 'unlocked')
      return isController
        ? localizeText(`${path}.unlock.controller`)
        : localizeText(`${path}.unlock.no_controller`)
    if (!isLockedToCurrentParty.value && !onePartyOnly)
      return localizeText(`${path}.unlock.cp_edit`)
    else return localizeText(`${path}.go`)
  })

  const questionnaireLink = AvvStore.state.toolbarData.questionnaireLink

  return {
    bShowQuestionnaireButton,
    questionnaireLink,
    questionnaireButtonTooltip,
    bQuestionnaireButtonDisabled
  }
}

export const init_import_docx_button = () => {
  const bEditorIsEmpty = ref(false)

  ;(async () => {
    await EditorFactory.onCreate('draft')
    const editor = EditorFactory.get('draft').get()
    await new Promise<void>((resolve) => {
      editor.onReady.subscribe((data, event) => {
        if (data.ready) event.unsub() && resolve()
      })
    })

    const resolve = () => {
      bEditorIsEmpty.value = editor.isEmpty()
    }

    editor.onChange.subscribe(resolve)
    resolve()
  })()

  const showImportDialog = () => {
    window
      .avv_upload<{ body: string }>({
        title: localizeText('template.docx_upload.title'),
        description: localizeText('template.docx_upload.desc'),
        path: '/documents/imports/docx',
        accept: '.docx',
        data: {
          document_id: AvvStore.state.document_id,
          automated: !bEditorIsEmpty.value
        }
      })
      .then(async (data) => {
        const editor = EditorFactory.main
        const node = HtmlParser.parse(data.body)
        AvvNodeGrammar.applyDocxGrammar(node)
        editor.load(node)

        if (EditorFactory.exists('draft')) {
          await editor.negotiation.asyncAcceptChanges()
          await editor.negotiation.asyncSubmitDeltaChange()

          await window.CompareUtils.saveVersion('uploaded')
        } else {
          window.templateChangeDelta = {}
        }
      })
  }

  const userHasRights = computed(() => {
    return (
      AvvStore.state.is_doc_negotiable &&
      AvvStore.state.active_participant &&
      AvvStore.state.active_participant.edit === 'All' &&
      AvvStore.state.locking_party ===
        AvvStore.state.active_participant.party_type
    )
  })

  const documentNotFinished = computed(() => {
    return (
      AvvStore.state.doc_state !== 'completed' &&
      AvvStore.state.doc_state !== 'signed'
    )
  })

  return {
    showImportDialog,
    bNegotiateImportEnabled: computed(
      () =>
        userHasRights.value &&
        documentNotFinished.value &&
        (bEditorIsEmpty.value
          ? AvvStore.state.allow_docx_import
          : AvvStore.state.allow_export_with_uuids)
    ),
    bNegotiateExportEnabled: computed(
      () =>
        userHasRights.value &&
        documentNotFinished.value &&
        AvvStore.state.allow_export_with_uuids
    )
  }
}

export const init_doc_name_field = () => {
  const documentName = ref(AvvStore.state.doc_name.replaceAll('&amp;', '&'))
  const regExp = /<|>/g
  watch(documentName, (newValue, oldValue) => {
    if (typeof newValue === 'string' && newValue.startsWith('http')) {
      documentName.value = oldValue
      return
    }

    if (!newValue) {
      documentName.value = oldValue
      return avv_dialog({
        alertMessage: localizeText('document.topbar.alerts.empty_title')
      })
    }

    if (documentName.value.match(regExp)) {
      documentName.value = newValue.replace(regExp, '')
      window.avv_toast({
        type: 'error',
        message: localizeText('document.notice.not_valid_characters'),
        inverted: true
      })
    }

    axios.post('/documents/save_title', {
      id: AvvStore.state.document_id,
      title: newValue
    })
  })

  return { documentName }
}

export const init_download = () => {
  const bShowDownloadDropdown = ref(false)
  const isPendingExternalSignature = computed(
    () => AvvStore.state.doc_state === 'pending_external_signature'
  )
  const hasRelatedDocuments = computed(
    () => !!AvvStore.state.related_documents.length
  )

  const downloadAttachments = ref(false)
  watch(downloadAttachments, () => {
    bShowDownloadDropdown.value = true
  })

  const downloadBlueprint = ref(false)
  watch(downloadBlueprint, () => {
    bShowDownloadDropdown.value = true
  })

  const bDownloadInternalComments = ref(true)
  watch(bDownloadInternalComments, () => {
    bShowDownloadDropdown.value = true
  })

  const download = (format: string) => {
    const data = {
      document_ids: [AvvStore.state.document_id],
      formats: [format],
      flags: [] as string[]
    }

    if (bDownloadInternalComments.value === false) {
      data.flags.push('no_private_comments')
    }

    if (downloadBlueprint.value) {
      data.formats.push('blueprint')
    }

    if (downloadAttachments.value) {
      data.formats.push('attachments')
    }

    if (downloadAllRelated.value) {
      const relatedDocumentIds = AvvStore.state.related_documents.map(
        (doc) => doc.id
      )
      data.document_ids.push(...relatedDocumentIds)
    }

    void window.avv_download(ExportApi.create.path(), data)
  }

  const handle_docx_download = (format: string) => {
    const hasPdfToDownload = Object.entries(AvvStore.state.exportableDocuments)
        .some(([id, doc]) => doc.formats.length === 1 && doc.formats[0] === 'document_pdf');

    if (downloadAllRelated.value && hasPdfToDownload) {
      window.avv_dialog({
        vueDialog: DownloadDialog,
        callback: () => {}
      })
    } else {
      download(format)
    }
  }

  window.addEventListener('click', (e) => {
    const isDownloadElement = (elem) => {
      if (!elem) return false
      if (elem.classList.contains('download')) return true
      if (elem.tagName.toLowerCase() === 'main') return false
      else return isDownloadElement(elem.parentElement)
    }
    if (!isDownloadElement(e.target)) bShowDownloadDropdown.value = false
  })

  const downloadPermissions = AvvStore.state.download
  const doc_id = AvvStore.state.document_id

  const bDownloadDisabled = !AvvStore.state.blocking_actions.download
  const bShowDownloadApprovalsTooltip = ref(false)
  const downloadAllRelated = ref(false)
  const setDownloadAllRelated = (value: boolean) =>
    (downloadAllRelated.value = value)

  return {
    bShowDownloadDropdown,
    download,
    downloadAttachments,
    downloadPermissions,
    doc_id,
    bDownloadDisabled,
    bDownloadInternalComments,
    bShowDownloadApprovalsTooltip,
    isPendingExternalSignature,
    hasRelatedDocuments,
    downloadAllRelated,
    setDownloadAllRelated,
    downloadBlueprint,
    handle_docx_download
  }
}

export const init_create_related_doc = () => {
  const relatedDocLink = AvvStore.state.paths.relatedDoc
  const onClickRelatedDoc = () => {
    const hiddenLink = document.querySelector('.related-doc')
      .firstElementChild as HTMLElement
    hiddenLink.click()
  }

  return { onClickRelatedDoc, relatedDocLink }
}

export const init_notify = () => {
  const bShowNotify =
    !AvvStore.state.doc_status.completed && !AvvStore.state.doc_status.signed
  const hasReminders = ref(AvvStore.state.hasReminders)
  const toggleReminders = async () => {
    const url = '/documents/change_reminders'
    const params = { document_id: AvvStore.state.document_id }
    await axios.post(url, params)
    hasReminders.value = !hasReminders.value
  }

  return { bShowNotify, hasReminders, toggleReminders }
}

export const init_delete = () => {
  const path = 'document.topbar.alerts'
  const canDelete = AvvStore.state.can_delete_doc
  const deleteDocLink = AvvStore.state.paths.deleteDoc
  const docDeleted = computed(() => AvvStore.state.doc_state === 'deleted')
  const action = docDeleted.value ? 'restore' : 'delete'

  const deleteDocument = () => {
    avv_dialog({
      confirmMessage: localizeText(`${path}.del_res_doc`, {
        action: localizeText(`${path}.${action}`)
      }),
      squareDisplay: true,
      warn: true,
      okButtonText: localizeText(`general.${action}`),
      confirmCallback: (value) => {
        if (value) {
          const deleteDocLink = document.querySelector('.delete-doc')
            .firstElementChild as HTMLElement
          deleteDocLink.click()
        }
      }
    })
  }

  return { canDelete, deleteDocument, deleteDocLink, docDeleted, action }
}

export const init_finish = () => {
  const canFinish = AvvStore.state.canFinishDoc

  const finishDocument = () => {
    avv_dialog({
      confirmMessage: localizeText('document.topbar.alerts.finish_res_doc', {
        action: localizeText('document.topbar.alerts.finish_document')
      }),
      squareDisplay: true,
      warn: true,
      okButtonText: localizeText('general.confirm'),
      confirmCallback: (value) => {
        if (value) {
          DocumentsApi.finish({
            data: {documents: AvvStore.state.document_id}
          }).then(() => {
            window.location.reload()
            return null;
          }).catch((error: Error) => {
            window.avv_toast({
              type: 'error',
              message: localizeText('document.topbar.alerts.finish_error', { error: error.message }),
              inverted: true
            })
          })
        }
      }
    })
  }

  return { canFinish, finishDocument }
}

export const init_mark_as_complete = (props) => {
  const path = 'document.topbar'
  const state = AvvStore.state
  const bShowMarkAsComplete =
    !state.canCurrentPartySign &&
    (!state.canCounterPartySign || AvvStore.getters.parties.length === 1) &&
    state.isInAuthorParty
  const bShowMarkAsCompleteDisabled = !state.isCurrentParticipantController
  const buttonType = state.doc_status.signed_at
    ? 'mark_in_review'
    : state.salesforce_integration
      ? 'salesforce'
      : 'mark_as_complete'

  const markAsCompleteTooltip = computed(() => {
    const markAction =
      buttonType === 'mark_as_complete'
        ? localizeText(`${path}.tooltips.mark_as_complete.actions.as_complete`)
        : localizeText(`${path}.tooltips.mark_as_complete.actions.in_review`)
    if (buttonType === 'salesforce')
      return state.isCurrentParticipantController
        ? localizeText(`${path}.tooltips.mark_as_complete.send_for_sign`)
        : localizeText(`${path}.tooltips.mark_as_complete.send_for_sign_d`)
    else {
      return state.isCurrentParticipantController
        ? localizeText(`${path}.tooltips.mark_as_complete.mark_action`, {
            action: markAction
          })
        : localizeText(`${path}.mark_as_complete.mark_action_d`, {
            action: markAction
          })
    }
  })
  const text = computed(() => {
    if (state.doc_status.signed_at)
      return localizeText(`${path}.content.mark_in_review`)
    if (state.salesforce_integration)
      return localizeText(`${path}.content.send_for_sig`)
    else return localizeText(`${path}.content.mark_as_complete`)
  })

  return {
    bShowMarkAsComplete,
    text,
    bShowMarkAsCompleteDisabled,
    markAsCompleteTooltip
  }
}

export const init_publish = () => {
  const path = 'document.topbar.tooltips.publish'
  const isController = AvvStore.state.isCurrentParticipantController
  const bShowPublish = AvvStore.getters.parties.length > 1
  const currentParty = AvvStore.state.active_participant?.party_type
  const lockedToCurrentParty = computed(
    () => AvvStore.state.locking_party === currentParty
  )
  const bPublishableParties = computed(() => {
    const possibleParties = Object.entries(AvvStore.state.assignable_roles)
      .filter((pr: Array<any>) => pr[0] !== currentParty && pr[1].length > 0)
      .map((pr: Array<any>) => pr[0])
    const existingParties = Object.values(AvvStore.state.users)
      .filter((u: any) => u.party_type !== currentParty)
      .map((u: any) => u.party_type)
    return possibleParties.length > 0 || existingParties.length > 0
  })
  const bPublishDisabled = computed(
    () =>
      !AvvStore.state.blocking_actions.send ||
      AvvStore.getters.parties.length === 1 ||
      !isController ||
      !lockedToCurrentParty.value ||
      !bPublishableParties.value
  )
  const publishedBy = AvvStore.state.doc_status.published_by

  const publishTooltip = computed(() => {
    if (publishedBy !== currentParty && AvvStore.state.doc_state !== 'locked')
      return AvvStore.state.isCurrentParticipantController
        ? localizeText(`${path}.disabled`)
        : localizeText(`${path}.disabled_no_c`)
    if (!lockedToCurrentParty.value && isController)
      return localizeText(`${path}.already_sent`)
    if (lockedToCurrentParty.value && isController)
      return localizeText(
        `${path}.${bPublishableParties.value ? 'send' : 'needs_invite'}`
      )
    if (!lockedToCurrentParty.value && !isController)
      return localizeText(`${path}.already_sent_no_c`)
    if (lockedToCurrentParty.value && !isController)
      return localizeText(`${path}.send_no_c`)
    else return 'No condition met'
  })

  const bShowPublishApprovalsTooltip = ref(false)
  const bPublishBlocked = !AvvStore.state.blocking_actions.send

  const onClickPublish = async (event) => {
    goTo(`/drafts/${AvvStore.state.document_id}/publish`, event)
  }

  return {
    bShowPublish,
    bPublishDisabled,
    bShowPublishApprovalsTooltip,
    publishTooltip,
    bPublishBlocked,
    onClickPublish
  }
}

export const init_sign = () => {
  const path = 'document.topbar'
  const state = AvvStore.state
  const other =
    AvvStore.getters.parties.length > 1 ? 'counterparty' : 'signatory'
  const tick_to_complete = state.tick_to_complete
  const wasPressed = state.doc_status.signed_at
  const signRequested = state.sign_requested
  const signatureBlocked = !AvvStore.state.blocking_actions.sign
  const bShowSign = state.canCurrentParticipantSign
  const signedByCurrentParticipant =
    state.doc_status.signed_by_participants.includes(
      state.active_participant?.id
    )
  const twoPartyMessage = localizeText(`${path}.alerts.sign_two_party`)
  const onePartyMessage = localizeText(`${path}.alerts.sign_one_party`)
  const bSignDisabled = computed(
    () =>
      !state.isCurrentParticipantController ||
      (AvvStore.state.doc_state === 'locked' &&
        (AvvStore.state.parties.length !== 1 ||
          !state.canCurrentParticipantSign) &&
        !state.can_skip_to_sign) ||
      wasPressed ||
      bHasNonAnsweredQs.value ||
      signedByCurrentParticipant ||
      (signRequested &&
        !['partially_signed', 'ready_to_sign'].includes(state.doc_state)) ||
      signatureBlocked
  )
  const isLockedToCurrentParty = computed(
    () => AvvStore.state.locking_party === state.active_participant?.party_type
  )
  const onClickSign = () => {
    if (bSignDisabled.value) return
    const confirmTitle = tick_to_complete
      ? localizeText(`${path}.alerts.tick_to_complete.title`)
      : localizeText(`${path}.alerts.sign_confirm`)
    const confirmMessage = tick_to_complete
      ? localizeText(`${path}.alerts.tick_to_complete.message`)
      : AvvStore.getters.parties.length === 1
        ? onePartyMessage
        : twoPartyMessage
    avv_dialog({
      confirmTitle,
      confirmMessage,
      squareDisplay: true,
      confirmCallback: (value) => {
        if (value) {
          window.location = state.sign_path
        }
      }
    })
  }

  const signText = state.tick_to_complete
    ? localizeText(`${path}.content.tick_to_complete`)
    : localizeText(`${path}.content.sign`)
  const signTooltip = computed(() => {
    const tooltip_path = `${path}.tooltips.${
      state.tick_to_complete ? 'tick' : 'sign'
    }`

    if (signatureBlocked) {
      return localizeText(`${tooltip_path}.blocked`)
    } else if (signedByCurrentParticipant) {
      return localizeText(`${tooltip_path}.signed`)
    } else if (
      AvvStore.state.doc_state === 'locked' &&
      !state.canCurrentParticipantSign
    ) {
      return localizeText(`${tooltip_path}.locked`)
    } else if (bHasNonAnsweredQs.value) {
      return localizeText(`${tooltip_path}.q_err`)
    } else if (wasPressed) {
      return localizeText(`${tooltip_path}.pressed`)
    } else if (
      signRequested &&
      !['partially_signed', 'ready_to_sign'].includes(state.doc_state)
    ) {
      return `${localizeText(`${path}.tooltips.accept.pressed`, {
        other
      })} \n ${
        state.canCurrentParticipantSign
          ? localizeText(
              `${path}.tooltips.accept.proceed_after${
                state.tick_to_complete ? '_tick' : ''
              }`
            )
          : ''
      }`
    } else if (state.can_skip_to_sign) {
      return state.isCurrentParticipantController
        ? localizeText(`${tooltip_path}.go`)
        : localizeText(`${tooltip_path}.go_no_c`)
    } else if (
      state.doc_state !== 'locked' ||
      AvvStore.getters.parties.length === 1
    ) {
      return localizeText(
        `${tooltip_path}.go${
          state.isCurrentParticipantController ? '' : '_no_c'
        }`
      )
    } else {
      return localizeText(`${tooltip_path}.needs_send`, {
        controller: isLockedToCurrentParty.value
          ? !AvvStore.state.isCurrentParticipantController
            ? localizeText(`${path}.tooltips.sign.controller`)
            : ''
          : `by a ${AvvStore.state.locking_party}`
      })
    }
  })

  return { bShowSign, bSignDisabled, onClickSign, signText, signTooltip }
}

export const init_request_sign = () => {
  const path = 'document.topbar'
  const state = AvvStore.state
  const other =
    AvvStore.getters.parties.length > 1 ? 'counterparty' : 'signatory'
  const wasPressed = state.sign_requested
  const tickToComplete = state.tick_to_complete
  const signatureBlocked = !AvvStore.state.blocking_actions.sign
  const bShowRequestSign =
    (state.canCurrentPartySign && !state.canCurrentParticipantSign) ||
    (!state.canCurrentPartySign && state.canCounterPartySign)
  const bRequestSignDisabled = computed(
    () =>
      !state.isCurrentParticipantController ||
      (state.doc_state !== 'unlocked' &&
        AvvStore.getters.parties.length !== 1) ||
      wasPressed ||
      AvvStore.state.doc_state === 'ready_to_sign' ||
      bHasNonAnsweredQs.value ||
      signatureBlocked
  )
  const requestSignText = state.canCurrentPartySign
    ? localizeText(
        `${path}.content.request_${tickToComplete ? 'tick' : 'sign'}`
      )
    : localizeText(`${path}.content.accept`)
  const nonSealedParties = computed(() =>
    AvvStore.getters.parties
      .filter((p) => !AvvStore.state.sealed_parties.includes(p))
      .join(' and ')
  )

  const requestPath = `${path}.tooltips.request_${
    tickToComplete ? 'tick' : 'sign'
  }`
  const tooltipPath = `${path}.tooltips.${tickToComplete ? 'tick' : 'sign'}`

  const acceptTooltip = computed(() => {
    if (state.canCurrentPartySign) {
      if (signatureBlocked) {
        return localizeText(`${tooltipPath}.blocked`)
      } else if (bHasNonAnsweredQs.value) {
        return localizeText(`${tooltipPath}.q_err`)
      } else if (wasPressed || AvvStore.state.doc_state === 'ready_to_sign') {
        if (AvvStore.state.doc_state === 'ready_to_sign') {
          return localizeText(`${requestPath}.requested`)
        } else if (AvvStore.getters.parties.length === 1) {
          return localizeText(`${requestPath}.request_pending_one`, {
            party: AvvStore.state.active_participant?.party_type
          })
        } else {
          return localizeText(`${requestPath}.request_pending`, {
            parties: nonSealedParties.value
          })
        }
      } else if (
        state.doc_state === 'unlocked' ||
        AvvStore.getters.parties.length === 1
      ) {
        return localizeText(
          `${requestPath}.go${
            state.isCurrentParticipantController ? '' : '_no_c'
          }`
        )
      } else {
        return localizeText(`${requestPath}.publish_err`)
      }
    } else {
      if (bHasNonAnsweredQs.value) {
        return localizeText(`${path}.tooltips.accept.q_err`)
      } else if (wasPressed) {
        return `${localizeText(`${path}.tooltips.accept.pressed`, {
          other
        })} \n ${
          state.canCurrentParticipantSign
            ? localizeText(
                `${path}.tooltips.accept.proceed_after${
                  tickToComplete ? '_tick' : ''
                }`
              )
            : ''
        }`
      } else if (
        state.doc_state === 'unlocked' &&
        AvvStore.getters.parties.length === 1
      ) {
        if (state.isCurrentParticipantController) {
          return 'Click this button to indicate aceptance of this draft of the document'
        } else {
          return 'A controller can press this button to indicate acceptance of this draft of the document'
        }
      } else if (wasPressed) {
        return localizeText(`${path}.tooltips.accept.publish_err`)
      } else {
        return localizeText(`${requestPath}.publish_err`)
      }
    }
  })

  const confirmKey = computed(() => {
    if (state.canCurrentParticipantSign) {
      if (tickToComplete) {
        return 'request_tick_confirm'
      } else {
        return 'request_sign_confirm'
      }
    } else if (state.canCurrentPartySign) {
      if (tickToComplete) {
        return 'request_party_tick_confirm'
      } else {
        return 'request_sign_confirm'
      }
    } else {
      return 'accept_confirm'
    }
  })

  const onClickRequestSign = () => {
    if (bRequestSignDisabled.value) return

    avv_dialog({
      confirmTitle: localizeText(`${path}.alerts.${confirmKey.value}.title`),
      confirmMessage: localizeText(
        `${path}.alerts.${confirmKey.value}.message`
      ),
      squareDisplay: true,
      confirmCallback: (value) => {
        if (value) {
          window.location.href = `/drafts/${AvvStore.state.document_id}/sign_request`
        }
      }
    })
  }

  return {
    bShowRequestSign,
    bRequestSignDisabled,
    onClickRequestSign,
    requestSignText,
    acceptTooltip
  }
}

export const init_download_debug = () => {
  const canDownloadDebug = computed(() => AvvStore.state.can_download_debug)
  const debugDownloadText = 'Download dump'
  const debugDownloadParams = computed(() => {
    const params = new URLSearchParams()
    params.set('doc_id', AvvStore.state.document_id)
    params.set('temp_id', AvvStore.state.template_id)
    return params.toString()
  })

  return { canDownloadDebug, debugDownloadText, debugDownloadParams }
}

export const init_integrations = () => {
  const hasIntegrations = computed(() => AvvStore.state.hasIntegrations)

  return { hasIntegrations }
}
